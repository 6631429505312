import Alpine from 'alpinejs';
import axios from 'axios';

Alpine.store('csrf', {
    name: null,
    value: null,

    async init() {
        try {
            // Load CSRF cookie
            const response = await axios.get('/api/csrf');

            // Save values
            window.csrfTokenName = response.data.csrfTokenName;
            this.name = response.data.csrfTokenName;

            window.csrfTokenValue = response.data.csrfTokenValue;
            this.value = response.data.csrfTokenValue;
        } catch (error) {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.error(`An error has occurred when retrieving the CSRF token: ${error.response.status} ${error.response.data.error}`)
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                console.error(`An error has occurred when retrieving the CSRF token: NO RESPONSE`);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.error(`An error has occurred when retrieving the CSRF token: ${error.message}`);
            }
        }
    },
});
