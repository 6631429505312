import Alpine from 'alpinejs';
import anime from 'animejs';

Alpine.data('faqCollection', (componentsIndex, onlyOpenOne) => ({
    componentsIndex: componentsIndex,
    onlyOpenOne: onlyOpenOne,
    faqs: [],

    /**
     * Saves all FAQs in an array for further references
     */
    init() {
        const faqs = this.$el.querySelectorAll('[data-type*="faq"]');
        faqs.forEach((faq) => {
            this.faqs.push({
                id: parseInt(faq.dataset.id),
                html: faq,
            });
        });
    },

    /**
     * This function is called when one of the children is toggled (opened /
     * closed) with '$dispatch('faq-on-toggle', ...)'
     *
     * @param $dispatch - The AlpineJS $dispatch function
     * @param {Object} data - Data transferred by the event
     * @param {number} data.componentsIndex - Integer of the component index in twig
     * @param {number} data.id - Integer of the Craft ID of the FAQ
     * @param {boolean} data.open - Whether the FAQ is now open or closed
     */
    onToggle($dispatch, data) {
        if (this.onlyOpenOne && data.open) {
            let faqsToClose = this.faqs.filter(faq => faq.id !== data.id);
            faqsToClose.forEach((faq) => {
                $dispatch('faq-on-close', {
                    componentsIndex: this.componentsIndex,
                    id: faq.id,
                });
            })
        }
    },
}));

Alpine.data('faq', (id, componentsIndex) => ({
    id: id,
    componentsIndex: componentsIndex,
    open: false,
    animation: null,

    /**
     * Returns an animation instance for smoothly opening.
     *
     * @returns {Object}
     */
    openAnimationSmooth() {
        return anime({
            targets: this.$refs.collapse,

            // Properties to animate
            maxHeight: this.$refs.collapse.scrollHeight,

            // Animation settings
            duration: 200,
            autoplay: false,
            easing: 'easeInOutQuad',
        });
    },

    /**
     * Returns an animation instance for instantly (reduce animation) opening.
     *
     * @returns {Object}
     */
    openAnimationInstant() {
        return anime({
            targets: this.$refs.collapse,

            // Properties to animate
            maxHeight: this.$refs.collapse.scrollHeight,

            // Animation settings
            duration: 0,
            autoplay: false,
            easing: 'easeInOutQuad',
        });
    },

    /**
     * Returns an animation instance for smoothly closing.
     *
     * @returns {Object}
     */
    closeAnimationSmooth() {
        return anime({
            targets: this.$refs.collapse,

            // Properties to animate
            maxHeight: 0,

            // Animation settings
            duration: 200,
            autoplay: false,
            easing: 'easeInOutQuad',
        });
    },

    /**
     * Returns an animation instance for instantly (reduce animation) closing.
     *
     * @returns {Object}
     */
    closeAnimationInstant() {
        return anime({
            targets: this.$refs.collapse,

            // Properties to animate
            maxHeight: 0,

            // Animation settings
            duration: 0,
            autoplay: false,
            easing: 'easeInOutQuad',
        });
    },

    /**
     * Classes that need to be added/removed before an animation begins
     */
    animationBegin() {
        if (this.open) {
            this.$refs.collapse.classList.remove('hidden');
        } else {
            this.$refs.collapse.classList.remove('!max-h-full');
        }
    },

    /**
     * Classes that need to be added/removed after an animation finished
     */
    animationComplete() {
        // Open state could have changed in the meantime, check again
        if (this.open) {
            // Force a max-height state, instead of specific pixels
            this.$refs.collapse.classList.add('!max-h-full');
        } else {
            this.$refs.collapse.classList.add('hidden');
        }
    },

    /**
     * Toggles the animation for opening and closing the FAQ
     *
     * @param $dispatch - The AlpineJS $dispatch function
     */
    async toggle($dispatch) {
        this.open = !this.open;
        this.animationBegin();

        $dispatch('faq-on-toggle', {
            componentsIndex: this.componentsIndex,
            id: this.id,
            open: this.open,
        });

        if (!this.animation) {
            const reduceAnimationQuery = window.matchMedia("(prefers-reduced-motion: reduce)");
            // Check if the media query matches or is not available.
            if (!reduceAnimationQuery || reduceAnimationQuery.matches) {
                if (this.open) {
                    this.animation = this.openAnimationInstant();
                } else {
                    this.animation = this.openAnimationInstant();
                }
            } else {
                if (this.open) {
                    this.animation = this.openAnimationSmooth();
                } else {
                    this.animation = this.closeAnimationSmooth();
                }
            }

            this.animation.play();
        } else {
            // When toggling while animation is already running, reverse
            this.animation.reverse();
            this.animation.play();
        }

        await this.animation.finished;
        this.animationComplete();
        this.animation = null;
    },

    /**
     * If open, closes the FAQ
     *
     * @param $dispatch - The AlpineJS $dispatch function
     */
    async close($dispatch) {
        // Only close if it's open
        if (this.open) {
            await this.toggle($dispatch);
        }
    },

    /**
     * Event when the FAQ Collection wants to close this FAQ (because another
     * one was opened, and only one is allowed to be open)
     *
     * @param $dispatch - The AlpineJS $dispatch function
     * @param {Object} data - Data transferred by the event
     * @param {number} data.componentsIndex - Integer of the component index in twig
     * @param {number} data.id - Integer of the Craft ID of the FAQ
     */
    async onClose($dispatch, data) {
        // Check that the event is for the correct faq
        if (
            data.componentsIndex === this.componentsIndex
            && data.id === this.id
        ) {
            await this.close($dispatch);
        }
    },
}));
